@import '../../../../../styles/settings.colors';
@import '../../../../../styles/settings.font-size';
@import '../../../../../styles/settings.media-queries';
@import '../../../../../styles/settings.shadows';
@import '../../../../../styles/settings.animation';
.menuIconButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > p {
    font-size: $font-size-x-small;
    line-height: 1.6rem;
    color: var(--kp-color-content-interactive-secondary);
  }
}
