@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.footer {
  width: 100%;
  flex-grow: 0;
  padding: 1.6rem 1.6rem 0.8rem 1.6rem;
  background-color: var(--kp-color-bg-primary);
  border-top: 0.1rem solid var(--kp-color-border-default);
  margin-top: 1.6rem;

  &.simple {
    padding-top: 0.8rem;
  }
}

.appStoreInfo {
  display: flex;
  justify-content: center;
  margin: 0.8rem;
  gap: 2.4rem;

  svg {
    height: 3.2rem;
    width: auto;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  line-height: 4.8rem;
  color: var(--kp-color-border-default);
  > * {
    max-width: fit-content;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .feedbackButton {
    padding: 0 0.6rem;
  }
}

.footerBottom {
  text-align: center;
  color: var(--kp-color-content-regular-tertiary);
  p {
    font-size: $font-size-x-small;
    line-height: 1.6rem;
  }
}
