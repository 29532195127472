@import '../../../../styles/settings.colors';
@import '../../../../styles/settings.font-size';
@import '../../../../styles/settings.media-queries';
@import '../../../../styles/settings.shadows';
@import '../../../../styles/settings.animation';
.badgeHolder {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
}
.badgeContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  right: 15%;
  top: 15%;
  height: 1.6rem;
  min-width: 1.6rem;
  padding: 0.1rem 0.4rem;
  border-radius: 1rem;
  border: 1px solid var(--kp-color-border-neutral);
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  transition: transform 225ms $animation-function-1 0ms;
  font-size: $font-size-x-small;
  line-height: 1;

  &.red {
    color: var(--kp-color-content-onFill);
    background: var(--kp-color-fill-alert);
  }

  &.lightBlue {
    color: var(--kp-color-content-info);
    background: var(--kp-color-bg-info-prom);
  }

  &.blue {
    color: var(--kp-color-content-onFill);
    background: var(--kp-color-fill-accent);
  }

  @include respond-below(sm) {
    right: 10%;

    padding: 0 0.4rem;
    min-width: 1.6rem;
    height: 1.6rem;
    font-size: $font-size-x-small;
    line-height: 1.6rem;

    &.furtherRight {
      right: -40%;
    }

    &.red {
      top: 15%;
    }
  }
}

.left {
  right: unset;
  left: -30%;
}

.hide {
  transform: scale(0) translate(50%, -50%);
}

.bottom {
  position: relative;

  svg {
    position: absolute;
    bottom: -2px;
    right: -2px;
  }
}

.green {
  fill: var(--kp-color-fill-positive);
}

.grey {
  fill: var(--kp-color-disabled-prom);
}
