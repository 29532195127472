@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.navHolder {
  display: flex;
  position: fixed;
  width: 100%;
  height: 5.4rem;
  bottom: 0px;
  font-size: $font-size-x-small;
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.04));
  z-index: 7;
  transition: transform 400ms ease;

  &[data-state='hide'] {
    transform: translate3d(0, 55px, 0);
  }
  &[data-state='show'] {
    transform: translate3d(0, 0, 0);
  }
}

.navItem {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--kp-color-border-default);
  background-color: var(--kp-color-bg-primary);
  color: var(--kp-color-content-interactive-secondary) !important;

  svg {
    stroke: var(--kp-color-content-interactive-secondary);
    margin-bottom: 0.2rem;
  }

  &.isActive {
    border-top: 2px solid var(--kp-color-form-bg-active);
    background-color: var(--kp-color-form-bg-selected);
    color: var(--kp-color-content-interactive-primary) !important;

    svg {
      stroke: var(--kp-color-content-interactive-primary);
    }
  }
}
