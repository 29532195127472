@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.title {
  color: var(--kp-color-content-regular-secondary);
  font-weight: bold;
  font-size: $font-size-regular;
  display: flex;
  justify-content: center;
}
.back {
  margin-left: -1.6rem;
}

.headerIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    font-size: $font-size-x-small;
    line-height: 1.6rem;
    color: var(--kp-color-content-interactive-secondary);
  }
}

.headerButton {
  margin-right: 2.4rem;
}
